import * as React from "react";
import {useState} from "react";
import {Box, Button} from "@mui/material";
import FormModal from "../organisms/FormModal";

const LeadButton = (props) => {
    const [openModal, setOpenModal] = useState<boolean>(false)
    if (!props.website?.live?.functions?.actions?.includes('Leads')) {
        return <></>
    } else {
        return <Box>
            <Button
                key={'button'}
                variant={"contained"}
                    sx={{
                        borderRadius: '79px',
                        marginTop: '32px',
                        maxWidth: '200px',
                        fontWeight: 'bold',
                        padding: {
                            xs: '8px 16px 8px 16px',
                            md: '16px 32px 16px 32px',
                        }
                    }}
                    color={props.color}
                    onClick={() => {
                        setOpenModal(true)
                    }}
            >
                {props.website?.live?.functions?.form?.buttonText}
            </Button>
            <FormModal
                open={openModal}
                siteId={props.website.jsonId}
                formDefinition={props.website?.live?.functions?.form}
                closeModal={() => {
                    setOpenModal(false)
                }}
            />
        </Box>
    }


}
export default LeadButton;