import * as React from "react";
import SideBySideTile from "../atoms/SideBySideTile";
import {Box, Typography} from "@mui/material";

const Steps = (props) => {
    return <>
        {props.steps?.length !== 0 ?
            <>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <Typography variant='h4' component='h2' color="primary.light">
                        How it works
                    </Typography>
                </Box>
                {props.steps.map((step, index) => {
                    return <SideBySideTile
                        key={'steps-products-'+ index}
                        name={step.name}
                        description={step.description}
                        image={props.images[index]?.node?.fluid?.src}
                        index={index}
                    />
                })
                }
            </>
            : undefined
        }
    </>;
}

export default Steps