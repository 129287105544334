import {Box, Button, TextField, Typography} from "@mui/material";
import * as React from 'react';
import {useState} from 'react';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90vw',
        md: '90vw'
    },
    maxWidth: 400,
    bgcolor: 'background.light',
    borderRadius: 4,
    p: 2,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
};


const FormModal = (props: {
    open: boolean,
    siteId: string,
    formDefinition,
    closeModal
}) => {

    const [email, setEmail] = useState<string>("");
    const [form, setForm] = useState<string>("");
    const [disabled, setDisabled] = useState<boolean>(false);

    const submit = async () => {
        setDisabled(true)
        try {
            const response = await fetch(process.env.GATSBY_LEAD_URL,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.GATSBY_LEAD_API_KEY,
                    },
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: JSON.stringify({
                        "siteId": props.siteId,
                        "fromEmail": email,
                        "form": form
                    })
                });
        } finally {
            setDisabled(false)
            props.closeModal();
        }
    }

    return <Modal
        open={props.open}
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Box id="modal-modal-description" sx={{
                m: 2,
                textAlign: 'center'
            }}>
                <Typography variant="h5">
                {props.formDefinition.formHeader}
                </Typography>
                
            </Box>
            <Box>
                <Field
                    title={props.formDefinition.emailHeader}
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    lines={1}
                />
                <Field
                    title={props.formDefinition.formHeader}
                    value={form}
                    onChange={(e: any) => setForm(e.target.value)}
                    lines={6}
                />

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse'
                }}>
                    <Button variant={"contained"}
                            sx={{
                                borderRadius: '80px',
                                marginTop: '0',
                                padding: '8px 24px 8px 24px',
                                marginLeft: '8px',
                                textTransform: "none"
                            }}
                            color={"primary"}
                            onClick={() => {
                                Promise.resolve(submit())
                            }}
                            disabled={disabled}
                    >
                        Send
                    </Button>
                    <Button variant={"text"}
                            sx={{
                                borderRadius: '80px',
                                marginTop: '0',
                                padding: '8px 24px 8px 24px',
                                marginLeft: '8px',
                                textTransform: "none"
                            }}
                            color="primary"
                            onClick={props.closeModal}
                    >
                        Close
                    </Button>
                </Box>
            </Box>
        </Box>
    </Modal>
}

const Field = (props: { title, value, onChange, lines }) => {
    return <Box mb={2}>
        <Box sx={{
            color: 'primary.main',
            textAlign: 'left',
        }}>
            {props.title}
        </Box>

        <Box sx={{display: 'flex'}}>
            <TextField
                onChange={props.onChange}
                value={props.value}
                variant="outlined"
                size={"small"}
                sx={{flexGrow: 1}}
                multiline
                rows={props.lines}
            />
        </Box>
    </Box>
}

export default FormModal