import {Box, Typography} from "@mui/material";
import * as React from "react";
import LeadButton from "../atoms/LeadButton";


const BottomLeadBox = (props) => {
    return <div style={{width: "100%"}}>
        <Box sx={{
            margin: 'auto',
            backgroundColor: 'primary.main',
            p: 7,
        }}>
            <Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: 'center',
                    mb: 7
                }}>
                    <Typography variant='h3'
                                color='secondary.main'
                    >

                        {props.website?.live?.functions?.form?.bottomHeader}
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: 'center',
                    mb: 2
                }}>

                    <LeadButton website={props.website} color='secondary'/>
                </Box>
            </Box>
        </Box>
    </div>
}

export default BottomLeadBox