import {Box, Container, Grid, Stack, Typography} from "@mui/material";
import * as React from "react";
import LeadButton from "./LeadButton";

const Header = (props) => {
    return <Box sx={{
        background:
            props.coverImage?.node?.fluid?.src ?
                'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' + props.coverImage?.node?.fluid?.src + ')'
                :
                'block',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        display: {md: 'flex'},
        justifyContent: 'center',
        backgroundColor: 'background.main',
        color: props.coverImage?.node?.fluid?.src ? 'secondary.main' : 'primary.main',
    }}>
        <Container sx={{
            pt: 4,
            pb: 4
        }}>
            <Stack spacing={4}>
                <Box>
                    <Typography
                        variant='h5'
                    >
                        {props.brand.name}
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        variant='h1'
                    >
                        {props.brand.briefDescription}
                    </Typography>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{
                                fontSize: '24px',
                                color: props.coverImage?.node?.fluid?.src ? 'secondary.light' : 'primary.light'
                            }}>
                                {props.brand.longDescription}
                            </Box>
                        </Grid>
                    </Grid>
                    <LeadButton website={props.website} color={props.coverImage?.node?.fluid?.src ?'secondary':'primary'}/>
                </Box>
            </Stack>
        </Container>
    </Box>;
}

export default Header