import {Box, Container, Stack} from "@mui/material";
import * as React from "react";
import Header from "../components/atoms/Header";
import Steps from "../components/organisms/Steps";
import Products from "../components/organisms/Products";
import Testimonials from "../components/organisms/Testimonials";
import Solutions from "../components/organisms/Solutions";
import MadeWith from "../components/atoms/MadeWith";
import BottomLeadBox from "../components/organisms/BottomLeadBox";

const sort = (first, second) => {
    const f = first.node.original.src.substring(first.node.original.src.lastIndexOf("/"))
    const s = second.node.original.src.substring(second.node.original.src.lastIndexOf("/"))
    if (f < s) return -1
    else return 1
}

const MainPage = (props: { data }) => {
    return <>
        <Header brand={props.data.brand} website={props.data.website}
                coverImage={
                    props.data.images.find((i) => i.node.original.src.includes("coverImage"))
                }
        />
        <Box sx={{
            display: {md: 'flex'},
            justifyContent: 'center',
            backgroundColor: 'background.main',
            color: 'primary.main',
            pt:4
        }}>
            <Container>
                <Stack spacing={4}>
                    <Products
                        products={props.data.products}
                        images={
                            props.data.images.filter((i) => i.node.original.src.includes("products"))
                                .sort(sort)
                        }/>
                    <Solutions
                        solutions={props.data.solutions}
                        images={
                            props.data.images.filter((i) => i.node.original.src.includes("solutions"))
                                .sort(sort)
                        }/>
                    <Steps
                        steps={props.data.steps}
                        images={
                            props.data.images.filter((i) => i.node.original.src.includes("steps"))
                                .sort(sort)
                        }/>
                    <Testimonials
                        testimonials={props.data.testimonials}
                        images={
                            props.data.images.filter((i) => i.node.original.src.includes("testimonials"))
                                .sort(sort)
                        }/>
                </Stack>
            </Container>
        </Box>
        <BottomLeadBox website={props.data.website}/>
        <MadeWith/>
    </>
}

export default MainPage