import {Box, Typography} from "@mui/material";
import * as React from "react";

const BigTile = (props) => {
    return <Box sx={{mb: 4,
        padding: 4,
        backgroundColor: 'background.light',
        borderRadius: 4,
        minHeight: '25vh',
        alignItems: "center"
    }}>
        <Box sx={{
            pb: 2
        }}>
            <Box>
                <Typography
                    variant='h3'
                >
                    {props.name}
                </Typography>
                {props.price ?
                    <Typography variant='h6' fontWeight='bold' color="primary.light">
                        {props.price}
                    </Typography> : null}
            </Box>
            <Typography
                variant='body1' component='p' fontWeight={400}
            >
                {props.description}
            </Typography>
        </Box>
        {props.image ? <>
            <Box sx={{
                display: {xs: 'flex', sm: 'flex', md: 'none'},
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Box>
                    <img
                        src={props.image}
                        alt={props.name}
                        width="100%"
                    />
                </Box>
            </Box>
            <Box sx={{display: {xs: 'none', md: 'block'}}}>
                <img
                    src={props.image}
                    alt={props.name}
                    width="100%"
                />
            </Box>
        </> : null}
    </Box>
}

export default BigTile